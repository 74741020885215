<template>
  <section class="cont cash">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <div class="box">
        <div style="display:flex" class="note-box">
          <div><span style="font-size:20px;font-weight:bold;margin-right:15px">平台剩余短信余额</span> {{balance}}条</div>
          <el-divider direction="vertical"></el-divider>
          <div><span style="margin-right:15px">已被占用条数</span> {{occupied}}条</div>
        </div>
        <!-- <el-button type="primary" @click="dialogsignVisible = true">默认签名</el-button> -->
       <el-button type="primary" @click="getCustomerConfig()">客服配置</el-button>
       <el-button type="primary" @click="dialogRecordVisible = true">操作记录</el-button>
      </div>
    </el-row>

    <el-row class="content-box m-top-20">
      <el-row class="table-box">
        <el-tabs value="hotel">
          <el-tab-pane label="酒店管理" name="hotel">
            <el-row class="search-box" style="margin-left:30px">
              <el-row class="search-row">
                <el-row class="search-item">
                  <label>酒店搜索：</label>
                  <el-input v-model="hotelName" clearable placeholder="请输入内容"></el-input>
                </el-row>
                <el-row class="search-item">
                  <label>状态筛选：</label>
                  <el-select v-model="accountStatus" @change="getHotelData(1)" :placeholder="$t('msg.select')" clearable>
                    <el-option v-for="item in stateOpt" :key="item.value" :label="item.value" :value="item.status"></el-option>
                  </el-select>
                  <el-button style="margin-left:30px" type="primary" @click="getHotelData(1)">搜索</el-button>
                </el-row>
              </el-row>
            </el-row>
            <el-table v-loading="tableLoad" :data="hotelData" border fit :stripe="true">
              <el-table-column prop="hotelName" label="酒店名称"></el-table-column>
              <!-- <el-table-column label="签名">
                <template slot-scope="scope">
                  {{scope.row.sign ? scope.row.sign : '默认'}}
                </template>
              </el-table-column> -->
              <el-table-column prop="smsBalance" label="剩余可用"></el-table-column>
              <el-table-column prop="usedQuantity" label="已用数量"></el-table-column>
              <el-table-column prop="useTime" label="最近使用时间"></el-table-column>
              <el-table-column label="状态">
                <template slot-scope="scope">{{ scope.row.accountStatus == 'YES' ? '暂停' : '正常' }}</template>
              </el-table-column>
              <el-table-column :label="$t('msg.operating')" fixed="right" width="220">
                <template slot-scope="scope">
                  <el-button type="text" @click="dialogNumVisible = true; operationNum = scope.row">
                    <el-link type="primary">操作数量</el-link>
                  </el-button>
                <el-button type="text">
                    <el-link type="primary" v-if="scope.row.accountStatus == 'NO'" @click="updateStatus(scope.row.hotelId, 'YES')">暂停使用</el-link>
                    <el-link type="primary" v-else @click="updateStatus(scope.row.hotelId, 'NO')">恢复使用</el-link>
                </el-button>
                </template>
              </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination v-show="hotelTotal" :currentPage="hotelPage" :total="hotelTotal" :page-size="hotelLimit" @handleSizeChangeSub="pageChange($event,'hotel')" @handleCurrentChangeSub="handlePaging($event,'hotel')"/>
          </el-tab-pane>
        </el-tabs>
      </el-row>
    </el-row>
    
    <!-- 操作数量弹窗 -->
    <el-dialog :visible.sync="dialogNumVisible" width="600px">
      <div slot="title">
        <span class="text-color font-18">操作数量</span>
        <span class="text-color font-14 m-left-10">{{ operationNum.hotelName }}</span>
      </div>
      <el-row class="operation-num">
        <div>
          <label>平台可使用数量：</label>
          <el-input :value="balance - occupied" disabled style="width:200px"></el-input>
        </div>
        <div class="tips">
            <i class="el-icon-warning" style="margin-right:10px"></i>
            平台余额-已被占用数量
        </div>
        <div>
          <label>该酒店剩余数量：</label>
          <el-input v-model="operationNum.smsBalance" disabled style="width:200px"></el-input>
        </div>
        <div style="margin-top:20px">操作</div>
        <div>
          <el-select v-model="operationStatus" style="width:100px">
            <el-option
              v-for="item in ['增加','减少']"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
           <el-input type="number" v-model="num" clearable placeholder="输入数量" style="width:200px;margin-left:10px"></el-input>
        </div>
        <div class="tips">
            <i class="el-icon-warning" style="margin-right:10px"></i>
            增加范围：1-平台可使用数量（整数） 减少范围：1-酒店剩余数量（整数）
        </div>
      </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogNumVisible = false">取 消</el-button>
        <el-button type="primary" @click="handelOperationNum()">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 操作记录弹窗 -->
    <el-dialog title="操作记录" :visible.sync="dialogRecordVisible">
      <el-table :data="operationRecord">
        <el-table-column prop="hotelName" label="酒店"></el-table-column>
        <el-table-column prop="createTime" label="操作时间" width="250"></el-table-column>
        <el-table-column label="类型">
          <template slot-scope="scope">
            {{scope.row.rechargeType == 'ADD' ? '增加' : '减少'}}
          </template>
        </el-table-column>
        <el-table-column prop="quantity" label="数量"></el-table-column>
        <el-table-column prop="balance" label="剩余数量"></el-table-column>
      </el-table>
       <!-- 分页 -->
      <pagination v-show="total" :total="total" :page-size="limit" @handleSizeChangeSub="pageChange" @handleCurrentChangeSub="handlePaging"/>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="dialogRecordVisible = false" type="primary">关闭</el-button>
      </span> -->
    </el-dialog>

     <!-- 客服配置弹窗 -->
    <el-dialog title="客服配置" :visible.sync="dialogCustomVisible" width="500px">
      <div class="custom-box">
        <div class="qrcode-box">
          <div class="qrcode-img">
            <img :src="qrCodeUrl" alt="" v-if="qrCodeUrl">
          </div>
          <div class="qrcode-text">二维码</div>
        </div>
        <div class="qrcode-link">
          <el-link type="primary" :underline="false" @click="visibleImage = true">{{qrCodeUrl == '' ? '上传二维码' : '更换二维码'}}</el-link>
        </div>
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogCustomVisible = false">关闭</el-button>
      </span> -->
    </el-dialog>

     <!-- 默认签名弹窗 -->
    <el-dialog title="签名" :visible.sync="dialogsignVisible" width="500px">
      <div>
          <label>默认签名：</label>
          <el-input v-model="sign" style="width:200px"></el-input>
      </div>
      <div class="tips">
        <i class="el-icon-warning" style="margin-right:10px"></i>
        默认签名在酒店没有设置自己签名时使用
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogsignVisible = false">关闭</el-button>
        <el-button type="primary" @click="dialogsignVisible = false">确 定</el-button>
      </span>
    </el-dialog>

     <!-- 图片上传 -->
      <Cropper :visible="visibleImage" @setImageInfo="getImageInfo" @closeUploadImg="closeUploadImg" :filmSize="filmSize"></Cropper>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import Cropper from '@/components/local/Cropper'
  import { verificationRule } from '@/common/js/common'
  import { saveSmsRecharge } from '@/api/smtech/noteManagement.js'

  export default {
    data() {
      return {
        crumbs: new Map([['营销云'], ['短信管理'], ['平台管理']]),
        balance: 0, // 平台短信余额
        occupied: 0, // 已被占用条数
        tableLoad: false, // 数据加载
        hotelData: [], // 酒店管理数据
        hotelTotal: 0,      // 酒店列表总条目数
        hotelPage: 1,       // 酒店列表当前页 默认第一页
        hotelLimit: 10,     // 酒店列表每页显示数
        dialogNumVisible: false, // 操作数量弹窗
        dialogRecordVisible: false, // 操作记录弹窗
        dialogsignVisible: false, // 签名弹窗
        dialogCustomVisible: false, // 客服配置弹窗
        visibleImage: false, // 图片上传会话框
        operationStatus: '增加', // 操作状态
        operationNum: {}, // 操作数量
        num: '', // 输入数量
        operationRecord: [], // 操作记录
        total: 0,      // 操作记录总条目数
        page: 1,       // 操作记录当前页 默认第一页
        limit: 10,     // 操作记录每页显示数
        sign: '达易住', // 签名
        stateOpt: [
          {
            value: '全部',
            status: ''
          },
          {
            value: '正常',
            status: 0
          },
          {
            value: '暂停',
            status: 1
          }
        ],
        accountStatus: '',   // 账户状态
        hotelName: '', // 酒店名称
        qrCodeUrl: '', // 客服配置二维码
      }
    },
    components: {Cropper},
    computed: {
      ...mapState(['hotelInfo']),
       // 图片裁剪尺寸
      filmSize() {
          return {
            autoCropWidth: 300,
            autoCropHeight: 300,
            fixedNumber: [1, 1],
            mode: '300px 300px',
          }
      },
    },
    mounted() {
      this.getPlatformBalance()
      this.getHotelData();
      this.getOperationRecord();
    },
    methods: {
      // 获取平台短信余额
      getPlatformBalance() {
        this.$axios.get('/hotel/hotelSmsBalance/getPlatformBalance', {}).then(res => {
          this.balance = res.data.balance
          this.occupied = res.data.occupied
        })
      },

      // 获取酒店管理数据
      getHotelData(page) {
        if (page) {
          this.hotelPage = page
        }
        this.tableLoad = true
        let param = {
          limit: this.hotelLimit,
          page: this.hotelPage,
          hotelName: this.hotelName,
          accountStatus: this.accountStatus
        }
        this.$axios.post('/hotel/hotelSmsBalance/getPage', param).then(res => {
            this.hotelData = res.records
            this.hotelTotal = res.total
            this.tableLoad = false
        })
      },

      // 获取操作记录
      getOperationRecord() {
        this.$axios.get('/hotel/hotelSmsBalance/getOperationPage', {limit:this.limit,page:this.page}).then(res => {
          this.operationRecord = res.records
          this.total = res.total
        })
      },

      // 获取客服配置
      getCustomerConfig() {
        this.dialogCustomVisible = true
        this.$axios.get('/hotel/hotelSmsBalance/getCustomerConfig', {}).then(res => {
          this.qrCodeUrl = res.data.qrCodeUrl
        })
      },

      // 保存客服配置
      saveCustomerConfig(qrCodeUrl) {
        this.$axios.post('/hotel/hotelSmsBalance/saveCustomerConfig', {qrCodeUrl}).then(res => {
          console.log(res)
        })
      },

       // 修改酒店状态
      updateStatus(hotelId, accountStatus) {
        let title, desc
        if (accountStatus == 'NO') {
          title = '恢复使用'
          desc = '是否取消限制该酒店使用短信？恢复使用后正常使用'
        } else {
          title = '暂停使用'
          desc = '是否限制该酒店使用短信？暂停使用后无法使用'
        }
        this.$confirm(desc, title, {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
        }).then(() => {
          this.$axios.post('/hotel/hotelSmsBalance/saveAccountStatus', {hotelId,accountStatus}).then(res => {
            if (res.success) {
              this.$message.success(`${title}成功`)
              this.getHotelData()
            }
          })
        })
      },

      // 确定操作数量
      handelOperationNum() {
        if (this.num == '' || this.num == 0) {
          this.$message.error(`请输入需要${this.operationStatus}的数量`)
          return
        }
        if (verificationRule.integer(this.num)) {
          this.$message.error('请输入整数')
          return
        }
        if (this.operationStatus == '增加') {
          if (this.num > (this.balance - this.occupied)) {
            this.$message.error('数量输入错误')
            return
          }
        } else {
          if (this.num > this.operationNum.smsBalance) {
            this.$message.error('数量输入错误')
            return
          }
        }

        let param = {
          hotelId:this.operationNum.hotelId,
          quantity: parseInt(this.num),
          rechargeType: this.operationStatus == '增加' ? 'ADD' : 'REDUCE'
        }

        saveSmsRecharge(param).then(res => {
          if (res.success) {
            this.$message.success('操作数量成功')
            this.dialogNumVisible = false
            this.getPlatformBalance()
            this.getHotelData()
            this.getOperationRecord()
          }   
        })
      },

      // 改变每页数
      pageChange(num, type) {
       if (type == 'hotel') {
        this.hotelLimit = num
        this.getHotelData()
       } else {
        this.limit = num
        this.getOperationRecord()
       }
      },

      // 改变当前页
      handlePaging(num, type) {
        if (type == 'hotel') {
          this.hotelPage = num;
          this.getHotelData();
        } else {
          this.page = num
          this.getOperationRecord()
        }
      },

      // 关闭上传图片会话框
      closeUploadImg() {
        this.visibleImage = false
      },
      // 获取上传图片信息
      getImageInfo({ url }) {
        this.visibleImage = false
        this.qrCodeUrl = url
        this.saveCustomerConfig(url)
      },
      // 关闭弹窗时触发
      closeDialog() {
        this.visibleImage = false
      },
    },
    watch: {
      dialogNumVisible(val) {
        if (!val) {
          this.num = ''
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .cash {
    .box {
      display: flex;
      justify-content: flex-end;
       position: relative;
      .note-box {
        display: flex;
        position: absolute;
        height: 140px;
        line-height: 140px;
        top: -50px;
        left: 10px;
        ::v-deep .el-divider--vertical {
          height: 60px;
          margin: 0 30px;
          margin-top: 40px;
        }
      }
    }
    .content-box {
      padding: 30px 50px;
    }
   
    .operation-num {
      div {
        line-height: 30px;
      }
    }

    .text-color {
      color: #303133;
    }

    .custom-box {
      display: flex;
      .qrcode-box {
        margin-right:20px;
        margin-left:50px;
        .qrcode-img {
          width:200px;
          height:200px;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .qrcode-text {
          text-align:center;
          margin-top:10px
        }
      }

      .qrcode-link {
        line-height:200px
      }
    }
  }

  .tips {
    background:#f2f2f2;
    color:#a1a1a1;
    padding:5px 10px;
    margin: 10px 0;
  }

    ::v-deep input::-webkit-outer-spin-button,
    ::v-deep input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }
    ::v-deep input[type="number"]{
        -moz-appearance: textfield;
    }
</style>
