import request from "@/api/service";

const api = {
   saveSmsRecharge: '/hotel/hotelSmsBalance/saveSmsRecharge', //操作数量
}

export function saveSmsRecharge(data) {
   return request({
      url: api.saveSmsRecharge,
      method: 'POST',
      data
   })
}






